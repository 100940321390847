<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0 shadow mb-5 bg-white rounded">
        <div class="row m-0 p-3">
          <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">Remarks</p>
          </div>
          <div class="col-12 col-xl-6 text-right">
            <button
              class="btn bt-main mx-0 mb-3 mb-xl-0"
              @click="add()"
              v-if="$roleaction('Insert Remarks')"
            >
              <span v-html="$svg_icon.add"></span> Add Remark
            </button>
          </div>
        </div>
        <hr class="m-0 p-0" />

        <div class="row m-0 p-4">
          <div class="col-12 col-xl-6">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <select class="custom-select" v-model="mode">
                  <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
                    {{ e.name }}
                  </option>
                </select>
              </div>

              <input
                type="search"
                class="form-control"
                placeholder="ค้นหา / Seach"
                v-model="find"
                v-on:keyup.enter="Search()"
              />
              <div class="input-group-append">
                <button class="btn bt-main" @click="Search()">ค้นหา</button>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-6">
            <div class="row  ">
              <div class="col-12 col-lg-3 text-right mb-3">
                หมวดหมู่ <span class="text-danger"></span>
              </div>
              <div class="col-12 col-lg-9 mb-3">
                <div class="input-group input-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="selectnow"
                    @click="CallModalfindbycatcode()"
                  />
                  <div class="input-group-append">
                    <button class="input-group-text" @click="Clearcatcode()">
                      x
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-6">
            <div class="row ">
              <div class="col-12 col-lg-8 text-right mb-3">
                กรุณาเลือก Mode <span class="text-danger"></span>
              </div>
              <div class="col-12 col-lg-4 mb-3">
                <div class="input-group input-group">
                  <select name="sel-mode" id="sel-mode" class="form-control">
                    <option value="all">ALL</option>
                    <option value="cat">CATCODE</option>
                    <option value="sku">SKCODE</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <p style="font-weight: bold;">
              จำนวน&nbsp;
              <span class="text-main">{{
                new Intl.NumberFormat().format(totalRows)
              }}</span>
              &nbsp;รายการ
            </p>
          </div>
          <div class="col-12">
            <b-table
              :empty-text="'ไม่พบข้อมูล / No Data'"
              :fields="fields"
              :items="items"
              :current-page="page_num"
              :per-page="page_size"
              outlined
              hover
              show-empty
              responsive
              :tbody-tr-class="rowClass"
              class="font-0-8s"
            >
              <template #cell(remarkHtmlTH)="row">
                <p v-html="row.item.remarkHtmlTH"></p>
              </template>
              <template #cell(remarkHtmlEN)="row">
                <p v-html="row.item.remarkHtmlEN"></p>
              </template>
              <template #cell(showAll)="row">
                <span class="badge badge-main" v-if="row.item.showAll == 1">
                  YES</span
                >
                <span class="badge badge-info-o" v-else> NO</span>
              </template>
              <template #cell(active)="row">
                <span class="badge badge-bnb" v-if="row.item.active == 1">
                  YES</span
                >
                <span class="badge badge-twd" v-else> NO</span>
              </template>
              <template #cell(action)="row">
                <b-icon
                  class="mr-3 text-black-50 "
                  type="button"
                  font-scale="1.3"
                  :icon="row.detailsShowing ? 'eye-slash-fill' : 'eye-fill'"
                  aria-hidden="true"
                  @click="row.toggleDetails"
                >
                </b-icon>
                <span
                  v-html="$svg_icon.edit"
                  v-if="$roleaction('Update Remarks')"
                  type="button"
                  class="mr-1 text-muted"
                  @click="edit(row.item)"
                ></span>
              </template>
              <template #row-details="row">
                <b-card>
                  <span class="badge badge-main">TH</span>
                  <div class="row">
                    <span v-html="row.item.remarkHtmlTH"></span>
                  </div>
                  <span class="badge badge-info-o">EN</span>
                  <div class="row">
                    <span v-html="row.item.remarkHtmlEN"></span>
                  </div>
                </b-card>
              </template>
            </b-table>
          </div>
          <div class="col-12">
            <div class="row justify-content-end">
              <div class="col-6 col-xl-1 input-group-prepend input-group-sm">
                <select class="custom-select" v-model="page_size">
                  <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                    {{ e }}
                  </option>
                </select>
              </div>
              <div class="col-6 col-xl-3">
                <b-pagination
                  v-model="page_num"
                  :total-rows="totalRows"
                  :per-page="page_size"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modalfindbycatcode :key="reModalfindbycatcode" />
  </div>
</template>

<script>
import Modalfindbycatcode from "../../components/Manage/Remarks/Modalfindbycatcode";
export default {
  name: "Remarks",
  components: { Modalfindbycatcode },
  data() {
    return {
      selectnow: this.$store.getters["categoryActions/get_category_remark"]
        .selectnow,
      catcode: this.$store.getters["categoryActions/get_category_remark"]
        .catCode,
      data_user: "",
      find: "",
      page_size: 10,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,
      totalRows: 1,
      mode: "explain",
      select_mode: [
        { name: "ค้นหาด้วยคำอธิบาย", key: "explain" },
        { name: "ค้นหา Active", key: "active" },
        { name: "ค้นหา ShowAll", key: "showAll" },
        { name: "ค้นหาโดยผู้อัพเดท", key: "UpdateByname" },
      ],
      fields: [
        {
          key: "No",
          label: "No.",
          class: "text-center",
          thStyle: { width: "2%" },
        },
        {
          key: "Mode",
          label: "Mode",
          class: "text-left",
          thStyle: { width: "10%" },
        },
        {
          key: "catName",
          label: "catName",
          class: "text-left",
          thStyle: { width: "10%" },
        },
        {
          key: "explain1",
          label: "ประเภท",
          class: "text-left",
          thStyle: { width: "10%" },
        },
        {
          key: "explain",
          label: "คำอธิบาย",
          class: "text-left",
          thStyle: { width: "10%" },
        },
        { key: "active", label: "Active", class: "text-center" },
        {
          key: "fupdateDate",
          label: "เวลาแสดงผล",
          class: "text-center",
          thStyle: { width: "10%" },
        },
        {
          key: "updateByname",
          label: "ชื่อ",
          class: "text-center",
          thStyle: { width: "20%" },
        },
        {
          key: "action",
          label: "",
          class: "text-right",
          thStyle: { width: "10%" },
        },
      ],
      items: [],
      itemlist: [],
    };
  },
  watch: {
    getcategory: async function(v) {
      this.selectnow = v.selectnow;
      this.catcode = v.catcode;
      await this.getpimremark();
    },
    page_num: function() {
      this.getpimremark();
    },
    find: function() {
      if (this.find == "") {
        this.unSearch();
      }
    },
  },
  computed: {
    getcategory: function() {
      return this.$store.getters["categoryActions/get_category_remark"];
    },
  },
  mounted() {
    this.getpimremark();
    this.Clearcatcode();
  },
  methods: {
    add() {
      this.$router.push({ name: "Manageremark", query: { mode: "add" } });
    },
    edit(e) {
      this.$router.push({
        name: "Manageremark",
        query: { mode: "edit", remarkId: e.remarkId },
      });
    },
    unSearch() {
      this.items = this.itemlist;
      this.totalRows = this.itemlist.length;
    },
    Search() {
      // console.log(data);
      var results = [];
      if (this.find == "") {
        this.getpimremark();
      } else {
        let find = this.find;
        if (this.mode !== "showAll" && this.mode !== "active") {
          find = this.find;
        } else {
          find =
            String(this.find).toUpperCase() == "YES"
              ? 1
              : String(this.find).toUpperCase() == "NO"
              ? 0
              : this.find;
        }
        var toSearch = String(find)
          .trim()
          .toLocaleUpperCase()
          .replace(/ /g, "");
        var objects = this.itemlist;
        for (var i = 0; i < objects.length; i++) {
          // for (var key in objects[i]) {
          var index = String(objects[i][this.mode])
            .trim()
            .toLocaleUpperCase()
            .replace(/ /g, "");
          if (index.indexOf(toSearch) != -1) {
            results.push(objects[i]);
          }
          // }
        }
        this.items = results;
        this.totalRows = this.items.length;
      }
    },
    async getpimremark() {
      let data = {
        find: this.find,
        page_size: this.page_size,
        page_num: this.page_num,
        mode: this.mode,
        remarkId: "",
        catCode:
          this.catcode == null || this.catcode == undefined ? "" : this.catcode,
      };
      let getAPI = await this.$serviceAPI.call_API(
        "post",
        `remark/getpimremark`,
        data,
        1
      );
      this.items = getAPI.data;
      this.itemlist = getAPI.data;
      this.totalRows = getAPI.data.length;

      try {
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    changepage(e) {
      this.page_size = e.target.value;
      this.page_num = 1;
      this.getuserole();
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.showAll == 1) return "table-main";
    },
    hide() {
      this.$bvModal.hide("Modalfindbycatcoderemark");
    },
    CallModalfindbycatcode() {
      this.reModalfindbycatcode = Date.now();
      setTimeout(() => {
        this.$bvModal.show("Modalfindbycatcoderemark");
      }, 100);
    },
    Clearcatcode() {
      this.selectnow = "";
      this.$store.dispatch("categoryActions/resetcategoryremark");
    },
  },
};
</script>
